@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.spinner {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #474bff 94%, #0000) top/14.1px 14.1px no-repeat,
    conic-gradient(#0000 30%, #474bff);
  -webkit-mask: radial-gradient(farthest-side,
      #0000 calc(100% - 14.1px),
      #000 0);
  animation: spinner-c7wet2 0.6s infinite linear;
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}

.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main-container {
  font-family: "Inter", sans-serif;
  height: 100vh;
  background-color: #efefef;
}

.header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  width: 100%;

  .image-logo {
    height: 46px;
    object-fit: contain;
    max-width: 90%;
    margin: 0 auto;
  }
}

.image-logo {
  object-fit: contain;
  width: 150px;
}

.p-4 {
  height: 24px;
  background-color: #efefef;
}

.sub-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
}

.innner-container {
  width: 500px;
  padding: 22px 24px 22px;
  background-color: #ffffff;
  border-radius: 4px;
}

.title {
  font-weight: 600;
  font-size: 32px;
  // color: #484848;
  margin-bottom: 12px;
  text-align: center;
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.new-address-container {
  @extend .flex;
  justify-content: space-between;
  margin: 0px 0px 8px 0px;
}

.address-card {
  border: 1px solid #e2e2e2;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 12px;

  .address-text {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .email-text {
    font-size: 12px;
    font-weight: 400;
    color: #888888;
  }

  .view-benefits {
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    color: #888888;
    cursor: pointer;
  }

  .subs-name {
    font-size: 28px;
    font-weight: 500;
    line-height: 33.89px;
    margin: 8px 0px 12px 0px;
  }
}

.submitBtn {
  width: 100%;
  height: 50px;
  color: white;
  border: 1px solid #000000;
  background-color: #000000;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 40px;
  font-weight: 500;
  cursor: pointer;
}

.individual-offer {
  height: fit-content;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding: 10px;
  gap: 15px;
  border-radius: 7px;
  border: solid 1px #f4f4f4;
  background-color: #fff;
}

.offer-img {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-right: 11.5px;
}

.new-offer-text {
  font-size: 12px;
  color: #15213d;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

//    thanaku page scss
.thankyou-title {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  line-height: 53.25px;
}

.sub-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: center;
  color: #8c8f9e;
  margin-top: 30px;

  span {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: center;
  }
}

.sub-description {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #8c8f9e;

  span {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
}

.email-container {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0;
  color: #8C8F9E;
  line-height: 28px;
}

.plan-container {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0;
  color: #8C8F9E;
  line-height: 28px;
}

.explore {
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-align: center;
  margin: 40px auto 16px auto;
  cursor: pointer;
  text-decoration-thickness: 2px;
}

.bold {
  width: 50%;
  color: #000;
  font-weight: 600;
  padding-left: 15px;
}

@media (max-width: 450px) {
  .p-4 {
    height: 8px;
  }
}

@media (max-width: 576px) {
  .address-card {
    .subs-name {
      font-size: 22px;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  .plan-container{
    font-size: 16px;
    font-weight: 400;
    // padding: ;
  }
  .innner-container {
    width: 100%;
  }

  .main-container {
    background-color: #ffffff;
  }

  .sub-container {
    background-color: #ffffff;
  }
}