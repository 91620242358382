.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal {
  width: 390px;
  min-width: 340px;
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin: 0px 20px;
}

.close-btn {
  position: absolute;
  top: -30px;
  right: 0px;
  cursor: pointer;
}

// input applied coupons csss

.data-wrapper {
  .couponStyle {
    font-size: 16px;
    color: black;
    font-weight: bold;
    margin: 0;
  }
  .inputwrapper {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 18px;
    .inputfield {
      height: 45px;
      padding: 13px 20px;
      border: 0.5px solid #eaeaea;
      background-color: #eaeaea;
      border-radius: 4px;
    }
    .inputfield:focus-visible {
      outline: none;
    }
    .input::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
    .buttonstyling {
      height: 50px;
      background-color: black;
      color: white;
      border-radius: 4px;
      outline: none;
      border: transparent;
      font-weight: 500;
      font-size: 18px;
      cursor: pointer;
    }
  }
}

//benefits and offers modal css

.header {
  height: 20px;
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
  .header-Inactive {
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    opacity: 0.4;
    padding: 0px 5px;
    letter-spacing: 0.3px;
  }
  .header-Active {
    @extend .header-Inactive;
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
    font-weight: 700;
    opacity: 1;
  }
}
.benefits {
  padding: 0px 10px;
  max-height: 65vh;
  overflow: scroll;
  .benefit-list {
    margin: 8px 0px;
    font-size: 12px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    img {
      width: 12px;
      height: 12px;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}
.offer-list {
  display: flex;
  background-color: #eaeaea;
  border-radius: 4px;
  height: 46px;
  margin: 10px 0px;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  font-size: 12px;
  .offer-name {
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.header-title {
  font-size: 20px;
  font-weight: 600;
}
.header-sub-title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
  margin-top: 4px;
}
.hr{
  margin: 2px 0px 20px 0px;
}
.slabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slab-breakdown {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  margin-top: 10px;
}
