.error-message {
  color: #ff0000;
  background-color: #ffe6e6;
  border: 1px solid #ffcccc;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100vh; /* Full viewport height to center vertically */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}

#loader {
  position: relative;
  display: flex;
  width: 25%;
  justify-content: space-between;
}
#loader div {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #d91e36;
}
#loader div:nth-child(1) {
  animation: loader 3s linear infinite 0s;
}
#loader div:nth-child(2) {
  animation: loader 3s linear infinite 0.15s;
}
#loader div:nth-child(3) {
  animation: loader 3s linear infinite 0.3s;
}
#loader div:nth-child(4) {
  animation: loader 3s linear infinite 0.45s;
}

@keyframes loader {
  0% {
    opacity: 0;
    transform: scale(0.3);
    background: #59cd90;
  }
  25% {
    opacity: 1;
    transform: scale(1.8);
    background: #0072bb;
  }
  50% {
    opacity: 0;
    transform: scale(0.3);
    background: #fe4a49;
  }
  75% {
    opacity: 1;
    transform: scale(1.8);
    background: #fed766;
  }
  100% {
    opacity: 0;
  }
}

.loader-text {
  text-align: center;
  font-size: 16px;
  color: #333;
  font-family: Arial, sans-serif; /* or any preferred font */
  margin-top: 60px;
  font-size: 24px;
}

@media screen and (max-width: 567px) {
  .loader-text {
    margin-top: 40px;
    font-size: 16px;
  }
}
