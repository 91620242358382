.container {
  max-width: 500px;
  margin: auto;
  margin-top: 32px;
  padding: 16px;
  position: relative;
}
#checkout > .App-Payment {
  border: none !important;
}
.cross-btn {
  width: 28px;
  height: 28px;
  background: rgb(88, 143, 187);
  position: absolute;
  right: 64px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
}
