@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.flex-center {
  display: flex;
  align-items: center;
}
.flex-justify-center {
  @extend .flex-center;
  justify-content: center;
}
.flex-justify-space-between {
  @extend .flex-center;
  justify-content: space-between;
}
.input-container {
  @extend .flex-center;
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 2px 8px;
  width: 47.2%;
  .country-code {
    margin: 0px 15px 0px 10px;
  }
  .vertical-line {
    border-right: 1px solid #dedede;
    height: 28px;
  }
  :global(.ant-input) {
    border: none;
    font-size: 14px;
  }
  :global(.ant-input:focus) {
    box-shadow: none;
  }
}

.dropDown-container {
  :global(.ant-select-selector) {
    border: 0px solid !important;
  }
}

.dropDown-search {
  @extend .flex-center;
  gap: 20px;
  margin-bottom: 10px;
  padding: 6px;
  img {
    margin-right: 6px;
  }
  :global(.ant-input) {
    background-color: #cbcbcb4d;
    font-family: Noto Sans;
    border-radius: 4px;
    padding: 8px;
    height: 35px;
    font-size: 14px;
    border: 0px solid;
  }
  :global(.ant-input:focus) {
    border-color: #e6e6e6;
    border-right-width: none;
    outline: 0;
    box-shadow: none;
  }
  :global(.ant-input:hover) {
    border-color: #e6e6e6 !important;
    border-right-width: none !important;
  }
}
.dropdown-flag {
  @extend .flex-center;
  gap: 20px;
  line-height: 1;
  .flag-code {
    font-size: 14px;
    font-weight: 500px;
  }
  .flag-name {
    @extend .flex-center;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
  }
  .flag {
    font-size: 25px;
  }
}
.input-style {
  :global(.ant-input) {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 8px;
    height: 40px;
    width: 100%;
  }
  :global(.ant-input:focus) {
    border-color: #d2d2d2;
    border-right-width: none;
    outline: 0;
    box-shadow: none;
  }
  :global(.ant-input:hover) {
    border-color: #d2d2d2 !important;
    border-right-width: none !important;
  }
}

.main-container {
  font-family: "Roboto", sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  .image-logo {
    height: 46px;
    object-fit: contain;
    max-width: 90%;
    margin: 0 auto;
  }
}

.container {
  @extend .flex-justify-center;
  margin-top: 20px;
}

.sub-container {
  width: 85%;
  display: flex;
  justify-content: space-between;
}

.first-container {
  width: 55%;
  padding: 0px 12px;
}
.first-name-input {
  @extend .input-style;
  margin-bottom: 8px;
}
.email-id-input {
  @extend .input-style;
  width: 50%;
}
.date-picker-input {
  border: 1px solid #dedede;
  border-radius: 4px;
  box-shadow: none;
  height: 42px;
  width: 50%;
}
.gender-input {
  width: 50%;
}
.pincode-input {
  @extend .input-style;
  width: 33%;
}
.address-name-input {
  @extend .input-style;
  margin-bottom: 8px;
}
.checkbox {
  @extend .flex-center;
  align-items: start;
  gap: 10px;
  padding: 12px 18px;
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  background-color: rgba(255, 255, 255, 0.05);
}
.billing-address {
  font-size: 14px;
  font-weight: 600;
  margin-top: 3px;
}
.same-as-address {
  font-size: 12px;
  font-weight: 400;
  margin-top: 6px;
  color: #878787;
}
.gst-id-input {
  @extend .input-style;
  width: 50%;
  margin-top: 3px;
}

.box1-container {
  border: 1px solid #d6d6d6;
  padding: 8px 12px 2px;
  border-radius: 8px;
}

.contact-details {
  @extend .flex-center;
  justify-content: space-between;
  margin: 10px 0px 16px 0px;
}

.contact-heading {
  @extend .flex-center;
  gap: 15px;
}

.contact-heading-text {
  font-size: 14px;
  font-weight: 600;
}

.already-have-account {
  font-size: 12px;
  font-weight: 400;
  color: #272727;
}

.login {
  font-size: 12px;
  font-weight: 600;
  color: #ac0000;
  text-decoration: underline;
  cursor: pointer;
}

.input-text-container1 {
  @extend .flex-center;
  gap: 10px;
  margin-bottom: 8px;
}
.input-text-container2 {
  @extend .flex-center;
  gap: 10px;
  margin-bottom: 8px;
}

.address-container {
  padding: 12px 18px;
  border-radius: 6px;
  background-color: #f2f2f2;
}

.address-name {
  font-size: 14px;
  font-weight: 600;
}

.address {
  font-size: 12px;
  font-weight: 400;
  color: #878787;
  margin-top: 6px;
}

.add-and-change-address {
  @extend .flex-center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 12px;
}

.add-address {
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  color: #272727;
  cursor: pointer;
}

.second-container {
  display: flex;
  flex-direction: column;
  width: 35%;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  padding: 16px 12px;
  height: fit-content;
}
.order-summary {
  border: 1px solid #d6d6d6;
  padding: 8px 10px;
  border-radius: 8px;
  .order-heading-text {
    font-size: 14px;
    font-weight: 600;
  }
  .order-details {
    @extend .flex-center;
    justify-content: space-between;
    margin: 4px 0px;
  }
  .order-price {
    font-size: 20px;
    font-weight: 600;
  }
  .subs-duration {
    font-size: 10px;
    font-weight: 400;
    color: #878787;
    margin-bottom: 8px;
  }
  .subs-plan-name {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
  }
  .view-benefits-offers {
    font-size: 10px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
  .change-plan {
    font-size: 12px;
    font-weight: 600;
    color: #ac0000;
    text-decoration: underline;
    cursor: pointer;
  }
  .apply-coupon-text {
    font-size: 12px;
    font-weight: 400;
    color: #878787;
  }
  .more-coupon-text {
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
  .apply-coupon-code {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    background-color: #dfffdd;
    padding: 2px 4px;
  }
  .apply-coupon-saved-rupess {
    @extend .apply-coupon-code;
    background-color: #f9f9f9;
  }
  .remove-coupon {
    font-size: 12px;
    font-weight: 600;
    color: #d02525;
    text-decoration: underline;
    cursor: pointer;
  }
  .price-amount {
    font-size: 12px;
    font-weight: 600;
  }
  .renew-text {
    font-size: 10px;
    font-weight: 400;
  }
  .renew-price {
    font-size: 10px;
    font-weight: 600;
  }
}
.payment-method-container {
  .select-payment {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin: 30px 0px 14px 0px;
  }
  .payment-method {
    @extend .flex-justify-space-between;
    padding: 0px 12px;
    .apple-pay {
      @extend .flex-justify-center;
      padding: 11px 0px;
      width: 48%;
      border-radius: 8px;
      border: 1px solid rgba(135, 135, 135, 0.3);
      cursor: pointer;
    }
    .google-pay {
      @extend .apple-pay;
      padding: 12px 0px;
    }
  }
}
.select-card {
  @extend .flex-justify-space-between;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  background-color: #fafafa;
  padding: 8px 12px;
  margin-bottom: 10px;

  .card-number {
    color: #272727;
    font-size: 14px;
    font-weight: 500;
  }
  .line {
    border: 1px solid #d6d6d6;
    margin-right: 10px;
    height: 25px;
  }
  .card-img {
    width: 30px;
    height: 20px;
  }
}
.add-card {
  @extend .flex-justify-center;
  font-size: 14px;
  font-weight: 600;
  gap: 16px;
  cursor: pointer;
}
.add-saved-address {
  @extend .add-card;
  background-color: #f2f2f2;
  border: 1px dashed #bdbdbd;
  height: 48px;
  border-radius: 6px;
  margin-bottom: 20px;
}
.address-list {
  overflow: scroll;
  height: 300px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.select-address-container {
  padding: 12px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
  margin-bottom: 20px;
}
.card-form-container {
  margin: 20px 0px 10px;
  .label-name {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
  }
  .expiration-date {
    @extend .flex-justify-center;
    gap: 16px;
  }
  .cvv-input {
    @extend .input-style;
  }
  .save-card {
    @extend .flex-center;
    gap: 6px;
    font-size: 14px;
    font-weight: 400;
    margin-top: 14px;
  }
}
.pay-btn {
  @extend .flex-justify-space-between;
  border: none;
  color: #ffffff;
  padding: 24px;
  border-radius: 4px;
  background-color: #d50810; 
  margin-top: 20px;
  cursor: pointer;
  .pay-btn-text {
    font-size: 18px;
    font-weight: 700;
  }
  .strick-price {
    text-decoration: line-through;
  }
}
.add-card-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.button-container {
  @extend .flex-center;
  justify-content: flex-end;
  gap: 14px;
  margin-top: 40px;
  .save-btn {
    border: none;
    width: 108px;
    height: 40px;
    border-radius: 4px;
    background-color: #000000;
    color: #ffffff;
    cursor: pointer;
  }
  .discard-btn {
    @extend .save-btn;
    background-color: #eaeaea;
    color: #000000;
  }
}

.apply-coupon {
  font-size: 14px;
  font-weight: 600;
}

.footer-container {
  padding: 16px 44px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  .footer-text {
    font-size: 10px;
    font-weight: 400;
    color: #6d6d6d;
    cursor: pointer;
  }
  .footer-hanldes{
    display: flex;
    text-decoration: underline;
    text-underline-offset:2px;
    gap: 16px;
  }
}

.contact-us-modal {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  margin: auto;
  padding: 100px 40px 0px;
  .backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    width: 100%;
    opacity: 0.75;
  }
  .contact-us-container {
    width: 650px;
    height: auto;
    position: absolute;
    padding: 32px;
    border-radius: 12px;
    background-color: #fff;
  }
  .contact-us-heading {
    font-size: 28px;
    font-weight: 500;
    color: #000;
    text-align: center;
  }
  
  .contact-us-body {
    display: flex;
  }
  .contact-us-body-left {
    width: 40%;
  }
  .contact-us-body-right {
    width: 60%;
  }
  .contact-us-left-item {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    width: max-content;
    color: #959595;
    line-height: 3;
  }
  
  .contact-us-right-item {
    font-size: 18px;
    text-align: left;
    color: #000;
    line-height: 3;
  }
  .cross-icon-container {
    cursor: pointer;
    position: relative;
    left: 102%;
    top: -48px;
  }
  .cross-icon {
    font-size: 15px;
    width: 34px;
    height: 34px;
    border: 3px solid #fff;
    border-radius: 30px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 16%);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: 600;
  }
}

@media (max-width: 900px) {
  .footer-container{
    display: none;
    flex-direction: column-reverse;
    gap: 10px;
  }
  .container {
    width: 100%;
    margin-bottom: 30px;
  }
  .sub-container {
    width: 100%;
    display: block;
    margin-bottom: 30px;
  }
  .first-container {
    width: auto;
  }
  .second-container {
    width: auto;
    border: none;
  }
  .input-text-container1 {
    display: block;
  }
  .email-id-input {
    width: 100%;
    margin-bottom: 8px;
  }
  .input-container {
    width: auto;
  }
  .pincode-input {
    width: 100%;
    margin-bottom: 8px;
  }
  .pay-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 12px;
  }
  
}
